import { Action, action } from "easy-peasy";

export interface WalletModel {
    walletBalance: number;
    updateWalletBalance: Action<WalletModel>;
}

const WalletStore: WalletModel = {
    walletBalance: 0,
    updateWalletBalance: action((state: any, data) => {
        state.walletBalance = data;
    }),

};

export default WalletStore;
