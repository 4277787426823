import { Action, action } from "easy-peasy";

export interface TemplateModel {
    templateName: string;
    setTemplateNames: Action<TemplateModel>;
}

const TemplateStore: TemplateModel = {
    templateName: "",
    setTemplateNames: action((state: any, data) => {
        state.templateName = data;
    }),

};

export default TemplateStore;
