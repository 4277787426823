import AuthStore, { AuthModel } from "store/auth";
import CurrentLocationStore, { CurrentLocationModel } from "store/currentLocation";
import DocLengthStore, { DocLengthModel } from "store/docLength";
import FolderStore, { FolderModel } from "store/folders";
import NotificationStore, { NotificationModel } from "store/notifications";
import ProfileStore, { ProfileModel } from "store/profile";
import SignatureStore, { SignatureModel } from "store/signature";
import SignatureAuditStore, { SignatureAuditModel } from "store/signatureAudit";
import StampStore, { StampModel } from "store/stamp";
import TeamUserStore, { TeamUserModel } from "store/teams";
import TemplateStore, { TemplateModel } from "store/template";
import FileStore, { FilesModel } from "store/uploadFile";
import ValuesForEditStore, { ValuesForEditModel } from "store/valuesForEdit";
import WalletStore, { WalletModel } from "store/wallet";
import WorkflowStore, { WorkflowModel } from "store/workflow";

export interface StoreModel {
    auth: AuthModel;
    file: FilesModel;
    profile: ProfileModel;
    signature: SignatureModel;
    signatureAudit: SignatureAuditModel;
    docLength: DocLengthModel;
    notificationCount: NotificationModel;
    teams: TeamUserModel;
    valForEditBtn: ValuesForEditModel;
    template: TemplateModel;
    workflow: WorkflowModel;
    wallet: WalletModel;
    folder: FolderModel;
    stamp: StampModel;
    location: CurrentLocationModel;
}

const Store: StoreModel = {
    auth: AuthStore,
    file: FileStore,
    profile: ProfileStore,
    signature: SignatureStore,
    signatureAudit: SignatureAuditStore,
    docLength: DocLengthStore,
    notificationCount: NotificationStore,
    template: TemplateStore,
    workflow: WorkflowStore,
    teams: TeamUserStore,
    valForEditBtn: ValuesForEditStore,
    wallet: WalletStore,
    folder: FolderStore,
    stamp: StampStore,
    location: CurrentLocationStore
};

export default Store;
