import { graphql } from "babel-plugin-relay/macro";

export const mutation = graphql`
    mutation createContactMutation($input: CreateContactInput!) {
        createContact(input: $input) {
            success
            contact {
                id
                name
                email
                createdAt
                status
            }
        }
    }
`;