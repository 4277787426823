import Loader from "components/Loader/Loader";
import { commitMutation, QueryRenderer as QR } from "react-relay";
import {
    Environment,
    fetchQuery as fQ,
    Network,
    RecordSource,
    Store
} from "relay-runtime";
import { AUTH_TOKEN } from "utils/constants";
import config from "./config";

const handlerProvider = null;
const source = new RecordSource();
const store = new Store(source);

const network = Network.create(async function fetchQuery(operation, variables) {
    const authToken = window.localStorage.getItem(AUTH_TOKEN);
    // const modifiedAuthToken = authToken?.slice(2);
    // const exactAuthToken = modifiedAuthToken?.substring(
    //     0,
    //     modifiedAuthToken.length - 1
    // );
    let headers: any = { "Content-Type": "application/json", "Accept": "application/json" };
    if (authToken) {
        headers["Authorization"] = "JWT " + authToken;
    }
    const response = await fetch(`${config}/graphql`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            query: operation.text,
            variables,
        }),
    });
    return await response.json();
});

const environment = new Environment({
    handlerProvider,
    network,
    store,
});

function mutateBase(props: any) {
    const onCompleted = (response: any, errors: any) => {
        if (errors && errors.length > 0) {
            var messages: string[] = [];
            errors.forEach((err: any) => {
                messages.push(err.message);
            });
            if (props.onFailure) {
                return props.onFailure(messages);
            } else {
                return alert(messages.join("; "));
            }
        }
        return props.onSuccess(response);
    };
    return commitMutation(props.environment, {
        mutation: props.mutation,
        variables: props.variables,
        onCompleted: onCompleted,
    });
}

function mutate(props: any) {
    return mutateBase({
        mutation: props.mutation,
        variables: props.input,
        onSuccess: props.onSuccess,
        onFailure: props.onFailure,
        environment,
    });
}

function QueryRenderer(_props: any) {
    const component = (
        <QR
            environment={environment}
            render={({ error, props }: { error: any; props: any }) => {
                if (error) {
                    return <div> {error.message} </div>;
                }
                if (props && Object.keys(props).length > 0) {
                    return _props.onSuccess(props);
                }
                if (_props.isTable) {
                    return (
                        <tr>
                            <td>Loading...</td>
                        </tr>
                    );
                }
                if (_props.isSelect) {
                    return <option>Loading...</option>;
                } else {
                    return <Loader />;
                }
            }}
            {..._props}
        />
    );
    return component;
}

function fetchQuery(query: any, variables = {}) {
    return fQ(environment, query, variables);
}

export { QueryRenderer, mutate, fetchQuery, environment, mutateBase };
