import React from "react";

function Loader({full=true}) {
    return (
        <div className={`loader-container ${full ? 'loader-container--bg':''}`}>
            <div className="loader"></div>
        </div>
    );
}

export default Loader;
