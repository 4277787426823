import { Action, action } from "easy-peasy";

export interface FolderModel {
    folderList: any[];
    updateFolderList: Action<FolderModel>;
    isDropSuccess: boolean;
    updateDropStatus: Action<FolderModel>;
}

const FolderStore: FolderModel = {
    folderList: [],
    updateFolderList: action((state: any, data) => {
        state.folderList = data;
    }),
    isDropSuccess: false,
    updateDropStatus: action((state: any, data) => {
        state.isDropSuccess = data;
    })
};

export default FolderStore;
