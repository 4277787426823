import { Action, action } from "easy-peasy";

export interface WorkflowModel {
    allWCount: Number;
    setAllWCount: Action<WorkflowModel>;
    allArchievedWCount: Number;
    setAllArchievedWCount: Action<WorkflowModel>;
}

const WorkFlowStore: WorkflowModel = {
    allWCount: 0,
    setAllWCount: action((state: any, data) => {
        state.allWCount = data;
    }),
    allArchievedWCount: 0,
    setAllArchievedWCount: action((state: any, data) => {
        state.setAllArchievedWCount = data;
    }),

};

export default WorkFlowStore;
