import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const supportedLngs = ['en', 'es', 'de'];


export const defaultNS = 'common';

//supported languages. Add to this array whenever new language support is added in your project 
const languages = supportedLngs;

i18n
    .use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        backend: {
            //translation file path in local
            // loadPath: "/assets/i18n/{{lng}}/{{ns}}.json",
            //load translations from backend server
            loadPath: (lng: any) => {
                if (lng[0] === "en") {
                    return "/locales/en/{{ns}}.json"
                } else {
                    return `${process.env.REACT_APP_BASE_URL}/static/locales/{{lng}}/{{ns}}.json`
                }
            }
        },
        fallbackLng: 'en',
        //disable in production
        debug: false,
        ns: ["common", "header"],
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        supportedLngs: languages,
        detection: {
            order: ['cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie', 'localStorage']
        },
        load: 'languageOnly',
        saveMissing: true,
        keySeparator: '.',
    });

export default i18n;