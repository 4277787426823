import { Action, action } from "easy-peasy";
import { ProxyObjectType, emptyProxyObject } from "utils/utils";

export interface StampDetailsModel {
    stampId: string;
    stampUrl: string;
}

export interface StampModel {
    stampDetails: StampDetailsModel | ProxyObjectType;
    stampReceiverUrl: string;
    setStampDetails: Action<
        StampModel,
        StampDetailsModel | ProxyObjectType
    >;
    setStampReceiverUrl: Action<StampModel>;
}

const StampStore: StampModel = {
    stampDetails: emptyProxyObject,
    stampReceiverUrl: "",
    setStampDetails: action((state: any, data) => {
        state.stampDetails = data;
    }),
    setStampReceiverUrl: action((state: any, payload: any) => {
        state.stampReceiverUrl = payload;
    }),
};

export default StampStore;
