import { Action, action } from "easy-peasy";

export interface CurrentLocationModel {
    currentLocation: String;
    setCurrentLocation: Action<CurrentLocationModel>;
}

const CurrentLocationStore: CurrentLocationModel = {
    currentLocation: '',
    setCurrentLocation: action((state: any, data) => {
        state.currentLocation = data;
    }),
};

export default CurrentLocationStore;
